import React, { useContext } from "react";
import { Navigate } from "react-router-dom";
import AuthContext from "../context/auth/AuthState";

interface PublicRouteProps {
  children: React.ReactNode;
  restricted: boolean;
}

interface AuthContextType {
  isLogin: boolean;
  user: any;
  token: string | null;
  Login: (data: any) => boolean;
  Logout: () => void;
}

const PublicRoute = ({
  children,
  restricted,
}: PublicRouteProps): JSX.Element => {
  const auth = useContext(AuthContext) as AuthContextType;
  const isLogin = auth?.isLogin ?? false;

  return isLogin && restricted ? <Navigate to='/' /> : <>{children}</>;
};

export default PublicRoute;
