/** @format */

import axios, { AxiosResponse, InternalAxiosRequestConfig } from "axios";

const serverAPI = axios.create({
  baseURL: process.env.REACT_APP_FE_URL,
});

serverAPI.interceptors.request.use(
  (config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem("token");
    if (token) {
      config.headers["x-access-token"] = token;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

serverAPI.interceptors.response.use(
  (response: AxiosResponse) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default serverAPI;
