/** @format */

import React from "react";

// Define props type
interface Header69Props {
  title: string;
  date: string;
  backgroundImage?: string;
}

// Header Component
const policyHeader: React.FC<Header69Props> = ({
  backgroundImage,
  title,
  date,
}) => {
  return (
    <div
      style={{ backgroundImage: `url(${backgroundImage})` }}
      className='relative object-cover bg-cover bg-no-repeat flex-col flex items-center justify-center h-[50vh] text-center text-white overflow-hidden'
    >
      {/* <img
        src={backgroundImage}
        alt='Header Image'
        className='absolute top-0 left-0 w-full h-full object-cover'
      /> */}
      <h1 className='text-[1.5rem] uppercase md:text-[2rem] lg:text-[3rem] font-extrabold text-[#EFF6FF]'>
        {title}
      </h1>
      <p className='text-[#E5E7EB] font-medium text-[1.1rem] '>{date}</p>
    </div>
  );
};

export default policyHeader;
