/** @format */

import React, { Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import LoadingAnimation from "./components/LoadingAnimation";
import PrivateRoute from "./utils/PrivateRoute";
import { Toaster } from "react-hot-toast";

import PublicRoute from "./utils/PublicRoute";
import JobApplication from "./pages/JobApplication";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import CookiePolicy from "./pages/CookiePolicy";
import TermsOfService from "./pages/TermsOfService";
const Home = React.lazy(() => import("../src/pages/home"));
const Contact = React.lazy(() => import("./pages/Contact"));
const Industries = React.lazy(() => import("./pages/Industries"));
const Portfolio = React.lazy(() => import("./pages/Portfolio"));
const About = React.lazy(() => import("./pages/About"));
const Careers = React.lazy(() => import("./pages/Careers"));
const Services = React.lazy(() => import("./pages/Services"));
const AllCaseStudies = React.lazy(() => import("./pages/AllCaseStudies"));
const Project = React.lazy(() => import("./pages/Project"));
const StaffAug = React.lazy(() => import("./pages/StaffAug"));
const AdminRoutes = React.lazy(() => import("./pages/AdminRoutes/Index"));

const IndividualCaseStudy = React.lazy(
  () => import("./pages/IndividualCaseStudy")
);
const LoginPage = React.lazy(() => import("./pages/Login"));

function ScrollToTop() {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  return (
    <Router>
      <Suspense fallback={<LoadingAnimation />}>
        <Toaster position='top-right' reverseOrder={false} />

        <ScrollToTop />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/portfolio' element={<Portfolio />} />
          <Route path='/industries' element={<Industries />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/job-applicaton/:id' element={<JobApplication />} />

          <Route path='/services' element={<Services />} />
          <Route path='/CaseStudies' element={<AllCaseStudies />} />
          <Route path='/CaseStudies/:title' element={<Project />} />
          <Route path='/StaffAugmentation' element={<StaffAug />} />
          <Route
            path='/individual-case-study/:id'
            element={<IndividualCaseStudy />}
          />
          <Route path='/Privacy-Policy' element={<PrivacyPolicy />} />
          <Route path='/Cookies-Policy' element={<CookiePolicy />} />
          <Route path='/Terms-of-Service' element={<TermsOfService />} />

          <Route
            path='/Admin-Panel/*'
            element={
              <PrivateRoute>
                <AdminRoutes />
              </PrivateRoute>
            }
          />

          <Route
            path='/Log-In'
            element={
              <PublicRoute restricted>
                <LoginPage />
              </PublicRoute>
            }
          />
        </Routes>
      </Suspense>
    </Router>
  );
}

export default App;
