/** @format */

import React from "react";
import { colors } from "../constants/theme";
import { Button } from "@relume_io/relume-ui";
import { Link } from "react-router-dom";
type ButtonProps = {
  children?: React.ReactNode;
  color?: string;
  variant?: "primary" | "secondary" | "danger" | "transparent";
  border?: string;
  sx?: React.CSSProperties;
  path?: string;
  onClick?: () => void; // Add onClick as optional
};

const SecondaryButton: React.FC<ButtonProps> = ({
  children,
  variant,
  sx,
  path,
  onClick, // Add onClick
}) => {
  const buttonClass = `
    ${variant === "secondary" ? colors.secondaryBtn : ""}
    ${variant === "transparent" ? colors.transparent : ""}
  `;

  const isLink = !!path;

  return isLink ? (
    <Link to={path}>
      <Button
        type='button'
        className={`w-full rounded-xl p-2 md:p-3`}
        style={{ background: buttonClass, ...sx }}
      >
        {children}
      </Button>
    </Link>
  ) : (
    <Button
      type='button'
      className={`w-full rounded-xl md:p-3 p-2`}
      style={{ background: buttonClass, ...sx }}
      onClick={onClick} // Pass onClick here
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
