/** @format */

import React from "react";

interface SectionProps {
  tag: keyof JSX.IntrinsicElements; // Ensures 'tag' is a valid HTML tag
  content: string | JSX.Element; // Can be text or JSX content
  className?: string; // Optional class for styling
}

interface ContentPageProps {
  sections: SectionProps[]; // Array of sections
}

const ContentPage: React.FC<ContentPageProps> = ({ sections }) => {
  return (
    <section className='container py-16 md:py-24 lg:py-28 px-[5%] mx-auto'>
      {sections.map((section, index) => {
        // Dynamically use the tag from section
        const Tag = section.tag;
        return (
          <Tag
            key={index}
            className={`mb-6 leading-7 text-[1.2em] text-justify text-gray-800 ${
              section.className || ""
            }`}
          >
            {section.content}
          </Tag>
        );
      })}
    </section>
  );
};

export default ContentPage;
