/** @format */

import { colors } from "../constants/theme";
import { Form, Formik } from "formik";
import { IoPersonCircleOutline, IoArrowBack } from "react-icons/io5";
import { MdOutlineMessage, MdEmail } from "react-icons/md";
import { BiPhoneIncoming } from "react-icons/bi";
import * as Yup from "yup";
import CustomField from "./CustomField";
import SecondaryButton from "./secondaryBtn";
import CustomUploadField from "./CustomUploadField";
import { Button } from "@relume_io/relume-ui";
import useCreateOrUpdate from "../hooks/useCreateOrUpdate";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import { useRef } from "react";

function JobApplicationComponent() {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const { post, loading, error } = useCreateOrUpdate();
  const { title } = useParams();

  const initialValues = {
    job: title || "",
    name: "",
    email: "",
    number: "",
    portfolio_link: "",
    linkedin_profile: "",
    message: "",
    resume: null,
  };

  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    number: Yup.string().required("Phone number is required"),
    resume: Yup.mixed()
      .required("Resume is required")
      .test(
        "fileType",
        "Only PDF, PNG, JPG, and JPEG files are allowed",
        (value) => {
          if (!value || !(value instanceof File)) {
            return false; // If no file or invalid type, return false
          }
          const allowedFormats = [
            "application/pdf",
            "image/png",
            "image/jpg",
            "image/jpeg",
          ];
          return allowedFormats.includes(value.type); // Validate file MIME type
        }
      ),
    portfolio_link: Yup.string().url("Invalid portfolio URL").notRequired(),
    linkedin_profile: Yup.string().url("Invalid LinkedIn URL").notRequired(),
  });

  interface JobApplicationValues {
    job: string;
    name: string;
    email: string;
    number: string;
    portfolio_link?: string; // Optional since it's not required
    linkedin_profile?: string;
    message?: string;
    resume: File | null; // Assuming resume is a file input, or null if not provided
  }

  const handleSubmit = async (
    values: JobApplicationValues,
    { resetForm }: any
  ) => {
    const formData = new FormData();
    formData.append("name", values.name);
    formData.append("email", values.email);
    formData.append("number", values.number);
    formData.append("portfolio_link", values.portfolio_link || "");
    formData.append("linkedin_profile", values.linkedin_profile || "");
    formData.append("message", values.message || "");
    if (values.resume) formData.append("resume", values.resume);

    try {
      await post(
        `${process.env.REACT_APP_FE_URL}/recruiterform/JobApplicationform/`,
        formData
      );
      toast.success("Form submitted successfully");
      resetForm();
      navigate(-1);

      // Reset file input field
      if (fileInputRef.current) {
        fileInputRef.current.value = "";
      }
    } catch (err) {
      toast.error("Error submitting form");
    }
  };

  const navigate = useNavigate();

  return (
    <section className='px-[5%] py-20 md:py-24 lg:py-28'>
      <div className='container'>
        <div className='grid grid-cols-1 gap-12 lg:grid-cols-2 md:gap-16'>
          <div className='lg:sticky lg:top-20 self-start'>
            <h5
              className='mb-1 font-thin'
              style={{
                background: colors.themeGradient,
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "transparent",
              }}
            >
              Start Your Journey
            </h5>
            <h2 className='rb-5 mb-5 flex items-center text-4xl font-extrabold md:mb-6 md:text-6xl lg:text-8xl'>
              Ready To
              <span
                className='ml-2 block'
                style={{
                  background: colors.themeGradient,
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                }}
              >
                Join?
              </span>
            </h2>
            <p className='md:text-md text-[#1F2937]'>
              Excited to be part of LCS? Submit your application and let's
              explore opportunities together.
            </p>
          </div>
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(values, actions) => handleSubmit(values, actions)}
            >
              {({ setFieldValue }) => (
                <Form>
                  <CustomField
                    name='name'
                    label='Name'
                    type='text'
                    placeholder='Enter your name'
                    required
                    icon={<IoPersonCircleOutline />}
                  />
                  <CustomField
                    name='email'
                    label='Email'
                    type='email'
                    placeholder='Enter your email'
                    required
                    icon={<MdEmail />}
                  />
                  <CustomField
                    name='number'
                    label='Phone'
                    type='number'
                    placeholder='Enter your phone number'
                    required
                    icon={<BiPhoneIncoming />}
                  />
                  <CustomField
                    name='portfolio_link'
                    label='Portfolio Link'
                    type='text'
                    placeholder='Enter your portfolio link'
                    icon={<IoPersonCircleOutline />}
                  />
                  <CustomField
                    name='linkedin_profile'
                    label='LinkedIn Profile'
                    type='text'
                    placeholder='Enter your LinkedIn profile link'
                    icon={<IoPersonCircleOutline />}
                  />
                  <CustomField
                    name='message'
                    label='Message Box'
                    type='text'
                    placeholder='Enter your message'
                    icon={<MdOutlineMessage />}
                  />
                  <CustomUploadField
                    name='resume'
                    label='Upload Resume'
                    required
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      const file = event.currentTarget.files
                        ? event.currentTarget.files[0]
                        : null;
                      setFieldValue("resume", file);
                    }}
                    ref={fileInputRef}
                  />
                  <div className='mt-6 flex gap-x-4 md:mt-8'>
                    <div className='w-[150px]'>
                      <SecondaryButton
                        color='blue'
                        variant='transparent'
                        sx={{
                          color: colors.blue500,
                          borderRadius: "8px",
                          border: `1px solid ${colors.blue500}`,
                        }}
                        onClick={() => navigate(-1)}
                      >
                        <IoArrowBack />
                        Back
                      </SecondaryButton>
                    </div>
                    <Button
                      style={{
                        border: "none",
                        borderRadius: "8px",
                        background:
                          "linear-gradient(90deg, #3B82F6 0%, #114FD7 100%)",
                      }}
                      variant='primary'
                      type='submit'
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <CircularProgress
                            size={20}
                            style={{ color: "white", marginRight: "8px" }}
                          />
                          Submitting
                        </>
                      ) : (
                        " Submit Application"
                      )}
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </section>
  );
}

export default JobApplicationComponent;
