/** @format */

import React from "react";
import { Navbar } from "../components/navbar";
import { Footer } from "../components/Footer";
import { ContactComponent } from "../components/contactComponent";
import PolicyHeader from "../components/PolicyHeader";
import background from "../assets/images/BackgroundSVG.svg";
import ContentPage from "../components/PolicyContent";

// Ensure SectionProps type is imported or defined
interface SectionProps {
  tag: keyof JSX.IntrinsicElements; // Ensures 'tag' is a valid HTML tag
  content: string | JSX.Element; // Accepts either plain text or JSX content
  className?: string; // Optional class for styling
}

function PrivacyPolicy() {
  const privacyNoticeSections: SectionProps[] = [
    {
      tag: "p",
      className: "",
      content: (
        <p className='font-extrabold'>
          This Privacy Notice for Low Code Systems Private Limited (doing
          business as LCS) ("we," "us," or "our"), describes how and why we
          might access, collect, store, use, and/or share ("process") your
          personal information when you use our services ("Services"), including
          when you:
        </p>
      ),
    },
    {
      tag: "ol",
      className: "pl-6",
      content: (
        <ol>
          <li>
            1. Visit our website at https://lowcosys.com/, or any website of
            ours that links to this Privacy Notice.
          </li>
          <li>
            2. Use LoCoSys Software Services. LCS specializes in providing
            tailored digital solutions through low-code, no-code, and full-stack
            development services. We empower businesses with rapid application
            development, custom software solutions, ERP systems, and seamless
            integration capabilities. Our expertise spans industries like
            healthcare, eCommerce, education, and manufacturing, offering
            scalable, user-centric applications designed for efficiency and
            growth. With a focus on innovation, operational optimization, and
            user engagement, LCS is your partner in delivering impactful,
            technology-driven results.
          </li>
          <li>
            3. Engage with us in other related ways, including any sales,
            marketing, or events.
          </li>
        </ol>
      ),
    },

    {
      tag: "p",
      content: `Questions or concerns?\n\nReading this Privacy Notice will help you understand your privacy rights and choices. We are responsible for making decisions about how your personal information is processed. If you do not agree with our policies and practices, please do not use our Services. If you still have any questions or concerns, please contact us at info@lowcosys.com.`,
    },
    {
      tag: "p",
      content: <strong>SUMMARY OF KEY POINTS</strong>,
    },
    {
      tag: "p",
      content: `This summary provides key points from our Privacy Notice, but you can find out more details about any of these topics by clicking the link following each key point or by using our table of contents below to find the section you are looking for.`,
    },

    {
      tag: "p",
      content: (
        <p>
          <strong>What personal information do we process?</strong> When you
          visit, use, or navigate our Services, we may process personal
          information depending on how you interact with us and the Services,
          the choices you make, and the products and features you use. Learn
          more about personal information you disclose to us.
        </p>
      ),
    },
    {
      tag: "p",
      content: (
        <p>
          <strong>Do we process any sensitive personal information?</strong>{" "}
          Some of the information may be considered "special" or "sensitive" in
          certain jurisdictions, for example your racial or ethnic origins,
          sexual orientation, and religious beliefs. We do not process sensitive
          personal information.
        </p>
      ),
    },
    {
      tag: "p",
      content: (
        <p>
          <strong>Do we collect any information from third parties? </strong> We
          do not collect any information from third parties.
        </p>
      ),
    },
    {
      tag: "p",
      content: (
        <p>
          <strong>How do we process your information? </strong> We process your
          information to provide, improve, and administer our Services,
          communicate with you, for security and fraud prevention, and to comply
          with law. We may also process your information for other purposes with
          your consent. We process your information only when we have a valid
          legal reason to do so. Learn more about how we process your
          information.
        </p>
      ),
    },
    {
      tag: "p",
      content: (
        <p>
          <strong>
            In what situations and with which parties do we share personal
            information?
          </strong>{" "}
          We may share information in specific situations and with specific
          third parties. Learn more about when and with whom we share your
          personal information.
        </p>
      ),
    },
    {
      tag: "p",
      content: (
        <p>
          <strong>How do we keep your information safe?</strong> We have
          adequate organizational and technical processes and procedures in
          place to protect your personal information. However, no electronic
          transmission over the internet or information storage technology can
          be guaranteed to be 100% secure, so we cannot promise or guarantee
          that hackers, cybercriminals, or other unauthorized third parties will
          not be able to defeat our security and improperly collect, access,
          steal, or modify your information. Learn more about how we keep your
          information safe.
        </p>
      ),
    },
    {
      tag: "p",
      content: (
        <p>
          <strong>What are your rights?</strong> Depending on where you are
          located geographically, the applicable privacy law may mean you have
          certain rights regarding your personal information. Learn more about
          your privacy rights.
        </p>
      ),
    },
    {
      tag: "p",
      content: (
        <p>
          <strong>How do you exercise your rights?</strong> The easiest way to
          exercise your rights is by submitting a data subject access request,
          or by contacting us. We will consider and act upon any request in
          accordance with applicable data protection laws.
        </p>
      ),
    },
    {
      tag: "p",
      content:
        "Want to learn more about what we do with any information we collect? Review the Privacy Notice in full.",
    },
    {
      tag: "h2",
      content: "TABLE OF CONTENTS",
    },
    {
      tag: "ol",
      content: (
        <ol>
          <li>1. WHAT INFORMATION DO WE COLLECT?</li>
          <li>2. HOW DO WE PROCESS YOUR INFORMATION?</li>
          <li>3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?</li>
          <li>4. HOW LONG DO WE KEEP YOUR INFORMATION?</li>
          <li>5. HOW DO WE KEEP YOUR INFORMATION SAFE?</li>
          <li>6. DO WE COLLECT INFORMATION FROM MINORS?</li>
          <li>7. WHAT ARE YOUR PRIVACY RIGHTS?</li>
          <li>8. CONTROLS FOR DO-NOT-TRACK FEATURES</li>
          <li>9. DO WE MAKE UPDATES TO THIS NOTICE?</li>
          <li>10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</li>
          <li>
            11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM
            YOU?
          </li>
        </ol>
      ),
    },
    {
      tag: "h2",
      content: (
        <>
          <strong className='text-xl md:text-2xl'>
            1. WHAT INFORMATION DO WE COLLECT?
          </strong>
          <p>
            <strong>Personal information you disclose to us</strong>
          </p>
          <p>
            In Short: We collect personal information that you provide to us.
          </p>
          <p>
            We collect personal information that you voluntarily provide to us
            when you express an interest in obtaining information about us or
            our products and Services, when you participate in activities on the
            Services, or otherwise when you contact us.
          </p>
          <p>
            <strong>Personal Information Provided by You:</strong>
          </p>
          <ul>
            <li>names</li>
            <li>phone numbers</li>
            <li>email addresses</li>
            <li>LinkedIn profile</li>
            <li>resume</li>
            <li>portfolio link</li>
            <li>message</li>
            <li>company name</li>
          </ul>
          <p>
            <strong>Sensitive Information:</strong> We do not process sensitive
            information.
          </p>
          <p>
            All personal information that you provide to us must be true,
            complete, and accurate, and you must notify us of any changes to
            such personal information.
          </p>
        </>
      ),
    },
    {
      tag: "h2",
      content: (
        <>
          <strong className='text-xl md:text-2xl'>
            2. HOW DO WE PROCESS YOUR INFORMATION?
          </strong>
          <p>
            In Short: We process your information to provide, improve, and
            administer our Services, communicate with you, for security and
            fraud prevention, and to comply with law. We may also process your
            information for other purposes with your consent.
          </p>
          <p>
            We process your personal information for a variety of reasons,
            depending on how you interact with our Services, including:
          </p>
          <ul>
            <li>
              <strong>
                To deliver and facilitate delivery of services to the user:
              </strong>{" "}
              We may process your information to provide you with the requested
              service.
            </li>
            <li>
              <strong>
                To respond to user inquiries/offer support to users:
              </strong>{" "}
              We may process your information to respond to your inquiries and
              solve any potential issues you might have with the requested
              service.
            </li>
            <li>
              <strong>To enable user-to-user communications:</strong> We may
              process your information if you choose to use any of our offerings
              that allow for communication with another user.
            </li>
            <li>
              <strong>To request feedback:</strong> We may process your
              information when necessary to request feedback and to contact you
              about your use of our Services.
            </li>
            <li>
              <strong>To post testimonials:</strong> We post testimonials on our
              Services that may contain personal information.
            </li>
          </ul>
        </>
      ),
    },
    {
      tag: "h2",
      content: (
        <>
          <strong className='text-xl md:text-2xl'>
            3. WHEN AND WITH WHOM DO WE SHARE YOUR PERSONAL INFORMATION?
          </strong>
          <p>
            In Short: We may share information in specific situations described
            in this section and/or with the following third parties.
          </p>
          <p>
            We may need to share your personal information in the following
            situations:
          </p>
          <ul>
            <li>
              <strong>Business Transfers:</strong> We may share or transfer your
              information in connection with, or during negotiations of, any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business to another company.
            </li>
          </ul>
        </>
      ),
    },
    {
      tag: "h2",
      content: (
        <>
          <strong className='text-xl md:text-2xl'>
            4. HOW LONG DO WE KEEP YOUR INFORMATION?
          </strong>
          <p>
            In Short: We keep your information for as long as necessary to
            fulfill the purposes outlined in this Privacy Notice unless
            otherwise required by law.
          </p>
          <p>
            We will only keep your personal information for as long as it is
            necessary for the purposes set out in this Privacy Notice, unless a
            longer retention period is required or permitted by law (such as
            tax, accounting, or other legal requirements).
          </p>
          <p>
            When we have no ongoing legitimate business need to process your
            personal information, we will either delete or anonymize such
            information, or, if this is not possible (for example, because your
            personal information has been stored in backup archives), then we
            will securely store your personal information and isolate it from
            any further processing until deletion is possible.
          </p>
        </>
      ),
    },
    {
      tag: "h2",
      content: (
        <>
          <strong className='text-xl md:text-2xl'>
            5. HOW DO WE KEEP YOUR INFORMATION SAFE?
          </strong>
          <p>
            In Short: We aim to protect your personal information through a
            system of organizational and technical security measures.
          </p>
          <p>
            We have implemented appropriate and reasonable technical and
            organizational security measures designed to protect the security of
            any personal information we process. However, despite our safeguards
            and efforts to secure your information, no electronic transmission
            over the Internet or information storage technology can be
            guaranteed to be 100% secure, so we cannot promise or guarantee that
            hackers, cybercriminals, or other unauthorized third parties will
            not be able to defeat our security and improperly collect, access,
            steal, or modify your information.
          </p>
          <p>
            Although we will do our best to protect your personal information,
            transmission of personal information to and from our Services is at
            your own risk. You should only access the Services within a secure
            environment.
          </p>
        </>
      ),
    },
    {
      tag: "h2",
      content: "6. DO WE COLLECT INFORMATION FROM MINORS?",
      className: " font-bold text-xl md:text-2xl",
    },
    {
      tag: "p",
      content: `
        In Short: We do not knowingly collect data from or market to children under 18 years of age.
        
        We do not knowingly collect, solicit data from, or market to children under 18 years of age, nor do we knowingly sell such personal information. By using the Services, you represent that you are at least 18 or that you are the parent or guardian of such a minor and consent to such minor dependent’s use of the Services. If we learn that personal information from users less than 18 years of age has been collected, we will deactivate the account and take reasonable measures to promptly delete such data from our records. If you become aware of any data we may have collected from children under age 18, please contact us at +91 92094 64600.
      `,
      className: "leading-7",
    },
    {
      tag: "h2",
      content: "7. WHAT ARE YOUR PRIVACY RIGHTS?",
      className: " font-bold text-xl md:text-2xl",
    },
    {
      tag: "p",
      content: `
        In Short: You may review, change, or terminate your account at any time, depending on your country, province, or state of residence.
        
        Withdrawing your consent: If we are relying on your consent to process your personal information, which may be express and/or implied consent depending on the applicable law, you have the right to withdraw your consent at any time. You can withdraw your consent at any time by contacting us by using the contact details provided in the section "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?" below.
        
        However, please note that this will not affect the lawfulness of the processing before its withdrawal nor, when applicable law allows, will it affect the processing of your personal information conducted in reliance on lawful processing grounds other than consent.
        
        If you have questions or comments about your privacy rights, you may email us at info@lowcosys.com.
      `,
      className: "leading-7",
    },
    {
      tag: "h2",
      content: "8. CONTROLS FOR DO-NOT-TRACK FEATURES",
      className: " font-bold text-xl md:text-2xl",
    },
    {
      tag: "p",
      content: `
        Most web browsers and some mobile operating systems and mobile applications include a Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not to have data about your online browsing activities monitored and collected. At this stage, no uniform technology standard for recognizing and implementing DNT signals has been finalized. As such, we do not currently respond to DNT browser signals or any other mechanism that automatically communicates your choice not to be tracked online. If a standard for online tracking is adopted that we must follow in the future, we will inform you about that practice in a revised version of this Privacy Notice.
      `,
      className: "leading-7",
    },
    {
      tag: "h2",
      content: "9. DO WE MAKE UPDATES TO THIS NOTICE?",
      className: " font-bold text-xl md:text-2xl",
    },
    {
      tag: "p",
      content: `
        In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.
        
        We may update this Privacy Notice from time to time. The updated version will be indicated by an updated "Revised" date at the top of this Privacy Notice. If we make material changes to this Privacy Notice, we may notify you either by prominently posting a notice of such changes or by directly sending you a notification. We encourage you to review this Privacy Notice frequently to be informed of how we are protecting your information.
      `,
      className: "leading-7",
    },
    {
      tag: "h2",
      content: "10. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?",
      className: " font-bold text-xl md:text-2xl",
    },
    {
      tag: "p",
      content:
        "If you have questions or comments about this notice, you may contact us by post at:",

      className: "leading-7",
    },
    {
      tag: "ul",
      content: (
        <ul>
          <li>Low Code Systems Private Limited</li>
          <li> 301-A, 3rd floor, Majestique Biznow,</li>
          <li> NIBM Road</li>
          <li> Pune, Maharashtra 411048, India.</li>
        </ul>
      ),
    },
    {
      tag: "h2",
      content:
        "11. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?",
      className: " font-bold text-xl md:text-2xl",
    },
    {
      tag: "p",
      content: `
        Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, details about how we have processed it, correct inaccuracies, or delete your personal information. You may also have the right to withdraw your consent to our processing of your personal information. These rights may be limited in some circumstances by applicable law. To request to review, update, or delete your personal information, please fill out and submit a data subject access request.
      `,
      className: "leading-7",
    },
  ];
  return (
    <div>
      <Navbar />
      <PolicyHeader
        title='PRIVACY POLICY'
        date='Last updated January 07, 2025'
        backgroundImage={background}
      />
      <ContentPage sections={privacyNoticeSections} />
      <ContactComponent />
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
