/** @format */

import { useState } from "react";
import axios from "axios";

const useCreateOrUpdate = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<any>(null);
  const [data, setData] = useState<any>(null);

  const request = async (
    url: string,
    method: "POST" | "PUT" | "GET" | "DELETE",
    body?: any
  ) => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios({
        url,
        method,
        data: body,
      });

      setData(response.data);
      setLoading(false);
    } catch (err) {
      setError(err);
      setLoading(false);
    }
  };

  const post = (url: string, body: any) => request(url, "POST", body);
  const put = (url: string, body: any) => request(url, "PUT", body);
  const get = (url: string) => request(url, "GET");
  const del = (url: string) => request(url, "DELETE");

  return {
    post,
    put,
    get,
    del,
    loading,
    error,
    data,
  };
};

export default useCreateOrUpdate;
