/** @format */

import React from "react";
import {
  FormLabel,
  InputBase,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { ErrorMessage, useField } from "formik";
import InfoIcon from "@mui/icons-material/Info";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

interface InputFieldProps {
  name: string;
  variant?: "outlined" | "filled" | "standard";
  info?: boolean;
  CustomInfoIcon?: React.ReactNode;
  infoText?: string;
  disable?: boolean;
  characterCount?: boolean;
  type?: string;
  disabled?: boolean;
  label?: string;
  color?: string;
  bg: boolean;
  style?: React.CSSProperties;
  sx?: React.CSSProperties;
  customStyles?: React.CSSProperties; // New prop for custom styles
  multiline?: boolean; // New prop to support multiline text
  required?: boolean;
  [key: string]: any;
}

const InputField: React.FC<InputFieldProps> = ({
  name,
  variant,
  bg = false,
  info,
  CustomInfoIcon,
  infoText,
  disable,
  characterCount,
  type,
  disabled = false,
  label,
  color,
  style,
  sx,
  customStyles = {}, // Default to empty object
  multiline = false, // Default to single line
  required,
  ...otherProps
}) => {
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = React.useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const styles: React.CSSProperties & { [key: string]: any } = {
    color: "#fff",
    minHeight: "40.95px",
    "label + &": {
      marginTop: "0.3rem",
    },
    "& .MuiInputBase-input": {
      borderRadius: type === "password" ? "0px 4px 4px 0px" : "4px",
      position: "relative",
      backgroundColor: bg ? "rgb(255 255 255 / 0.1)" : "#fff",
      fontSize: "1rem",
      height: "28px",
      width: "100%",
      padding: "8px 16px",
      transition: "border-color 0.3s, background-color 0.3s, box-shadow 0.3s",
      "&:focus": {
        boxShadow: `0px 4px 10px 0px rgba(0, 0, 0, 0.15)`,
        border: "1px solid white",
      },
    },
    "& input::placeholder": {
      fontSize: "1rem",
      fontWeight: 500,
    },
    "& .MuiInputAdornment-root.MuiInputAdornment-positionEnd": {
      backgroundColor: "#fff",
      padding: "21px 12px",
      fontSize: "10px",
      marginLeft: "0px",
      borderTopRightRadius: type === "password" ? "0" : "4px",
      borderBottomRightRadius: type === "password" ? "0" : "4px",
    },
    "& .MuiInputAdornment-root.MuiInputAdornment-positionStart": {
      backgroundColor: "#dfe8e1",
      padding: "22px 12px",
      fontSize: "10px",
      marginRight: "0px",
      borderTopLeftRadius: type === "password" ? "4px" : "4px",
      borderBottomLeftRadius: type === "password" ? "4px" : "4px",
    },
    ...sx,
    ...customStyles, // Apply custom styles here
  };

  const textFieldConfig: any = {
    sx: styles,
    variant: variant || "outlined",
    InputLabelProps: { shrink: true },
    fullWidth: true,
    multiline, // Enable multiline if the prop is true
    rows: multiline ? 7 : 1, // Set default rows for multiline
    ...otherProps,
  };

  if (meta && meta.touched && meta.error) {
    textFieldConfig.error = true;
    textFieldConfig.helperText = meta.error;
  }

  return (
    <div style={style}>
      {label && (
        <FormLabel>
          {label}
          {required && "*"}
        </FormLabel>
      )}
      <InputBase
        {...field}
        {...textFieldConfig}
        type={type === "password" && !showPassword ? type : "text"}
        endAdornment={
          info ? (
            <InputAdornment position='end'>
              {CustomInfoIcon || <InfoIcon />}
            </InputAdornment>
          ) : null
        }
        startAdornment={
          type === "password" ? (
            <InputAdornment position='start'>
              <IconButton onClick={togglePasswordVisibility}>
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </IconButton>
            </InputAdornment>
          ) : null
        }
      />
      {characterCount && <span>{field.value.length} / 100</span>}
      <ErrorMessage
        name={name}
        render={(msg: string) => (
          <div
            style={{
              fontFamily: "sans-serif",
              fontSize: "0.8rem",
              color: "red",
              paddingTop: "0.2rem",
            }}
          >
            {msg}
          </div>
        )}
      />
    </div>
  );
};

export default InputField;
