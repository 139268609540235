/** @format */

import React, { useState, forwardRef } from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ClearIcon from "@mui/icons-material/Clear";
import { useField } from "formik";

interface CustomUploadFieldProps {
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  sx?: object; // MUI sx prop for styling
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CustomUploadField = forwardRef<HTMLInputElement, CustomUploadFieldProps>(
  (
    { name, label, required = false, disabled = false, onChange, sx = {} },
    ref
  ) => {
    const [field, meta, helpers] = useField(name);
    const [fileName, setFileName] = useState<string>("");

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files ? event.target.files[0] : null;
      if (file) {
        setFileName(file.name);
        helpers.setValue(file); // Set the file object in Formik state, not just the file name
      }
    };

    const handleClear = () => {
      setFileName("");
      helpers.setValue(null); // Reset Formik field value to null
    };

    return (
      <div style={{ marginBottom: "16px" }}>
        {/* Label rendered above the field */}
        <label
          htmlFor={name}
          style={{
            display: "block",
            fontWeight: 500,
            marginBottom: "6px",
            fontSize: "1rem",
          }}
        >
          {label}
          {required && (
            <span style={{ color: "red", marginLeft: "4px" }}>*</span>
          )}
        </label>

        <TextField
          value={fileName} // Display file name in the input field
          placeholder='No file selected'
          disabled={disabled}
          fullWidth
          InputProps={{
            startAdornment: fileName && (
              <InputAdornment position='start'>
                <UploadFileIcon />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position='end'>
                {fileName && (
                  <IconButton onClick={handleClear} disabled={disabled}>
                    <ClearIcon />
                  </IconButton>
                )}
                <label htmlFor={`upload-button-${name}`}>
                  <input
                    type='file'
                    id={`upload-button-${name}`}
                    style={{ display: "none" }}
                    onChange={(e) => {
                      handleFileChange(e); // Handle file change internally
                      onChange(e); // Optionally trigger external onChange if needed
                    }}
                    ref={ref} // Forward the ref to the file input
                  />
                  <IconButton component='span' disabled={disabled}>
                    <UploadFileIcon />
                  </IconButton>
                </label>
              </InputAdornment>
            ),
          }}
          error={meta.touched && Boolean(meta.error)}
          helperText={meta.touched && meta.error ? meta.error : ""}
          sx={{
            "& .MuiInputBase-root": {
              height: "48px",
              border: "1px solid",
              borderColor: meta.touched && meta.error ? "red" : "#9CA3AF", // Error color
              backgroundColor: "#00000008",
              transition: "border-color 0.3s ease",
            },
            "& .MuiInputBase-root.Mui-focused": {
              borderColor: "#9CA3AF", // Focus color
            },
            "& .MuiOutlinedInput-input": {
              padding: "10px 14px",
            },
            "& .MuiFormHelperText-root": {
              fontSize: "0.8rem",
              color: "red",
            },
            ...sx,
          }}
        />
      </div>
    );
  }
);

CustomUploadField.displayName = "CustomUploadField";

export default CustomUploadField;
