/** @format */

import { FaXTwitter } from "react-icons/fa6";
import Logo from "../assets/images/logo.svg";
import {
  BiLogoFacebookCircle,
  BiLogoInstagram,
  BiLogoLinkedinSquare,
  BiLogoYoutube,
} from "react-icons/bi";
import { Link } from "react-router-dom";

type ImageProps = {
  url?: string;
  src: string;
  alt?: string;
};

type Links = {
  title: string;
  url: string;
};

type SocialMediaLinks = {
  url: string;
  icon: React.ReactNode;
};

type ColumnLinks = {
  links: Links[];
};

type Address = {
  label: string;
  value: string;
};

type Contact = {
  label: string;
  phone: string;
  email: string;
};

type Props = {
  logo: ImageProps;
  address: Address;
  contact: Contact;
  columnLinks: ColumnLinks[];
  socialMediaLinks: SocialMediaLinks[];
  footerText?: string;
  footerLinks: Links[];
};

export type Footer3Props = React.ComponentPropsWithoutRef<"section"> &
  Partial<Props>;

export const Footer = (props: Footer3Props) => {
  const {
    logo,
    address,
    contact,
    columnLinks,
    socialMediaLinks,
    footerText,
    footerLinks,
  } = {
    ...Footer3Defaults,
    ...props,
  } as Props;
  return (
    <footer className='px-[5%] py-12 md:py-18 lg:py-20 bg-gray-900'>
      <div className='container'>
        <div className='grid grid-cols-1 gap-x-[4vw] gap-y-12 pb-12 md:gap-y-16 md:pb-18 lg:grid-cols-[1fr_0.5fr] lg:gap-y-4 lg:pb-20'>
          <div>
            <div className='rb-6 mb-6 md:mb-8'>
              <a href={logo.url}>
                <img src={logo.src} alt={logo.alt} className='inline-block' />
              </a>
            </div>
            <div className='rb-6 mb-6 md:mb-8'>
              <div className='text-gray-100'>
                <p className='mb-1 text-sm font-semibold'>{address.label}</p>
                <p className='mb-5 text-sm md:mb-6'>{address.value}</p>
              </div>
              <div className='text-gray-100'>
                <p className='mb-1 text-sm font-semibold'>{contact.label}</p>
                <p className='flex flex-col text-sm underline decoration-black underline-offset-1 md:mb-6'>
                  <a href={`tel:${contact.phone}`}>{contact.phone}</a>
                  <a href={`mailto:${contact.email}`}>{contact.email}</a>
                </p>
              </div>
            </div>
            <div className='grid text-gray-100 grid-flow-col grid-cols-[max-content] items-start justify-start gap-x-3'>
              {socialMediaLinks.map((link, index) => (
                <a key={index} href={link.url}>
                  {link.icon}
                </a>
              ))}
            </div>
          </div>
          <div className='grid text-gray-100 grid-cols-1 items-start gap-x-6 gap-y-10 md:grid-cols-2 md:gap-x-8 md:gap-y-4'>
            {columnLinks.map((column, index) => (
              <ul key={index}>
                {column.links.map((link, linkIndex) => (
                  <li key={linkIndex} className='py-2 text-sm font-semibold'>
                    <Link to={link.url}>{link.title}</Link>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className='h-px text-gray-100 w-full bg-gray-100' />
        <div className='flex text-gray-100 flex-col-reverse items-start justify-between pb-4 pt-6 text-sm md:flex-row md:items-center md:pb-0 md:pt-8'>
          <p className='mt-8 md:mt-0'>{footerText}</p>
          <ul className='grid grid-flow-row grid-cols-[max-content] justify-center gap-y-4 text-sm md:grid-flow-col md:gap-x-6 md:gap-y-0'>
            {footerLinks.map((link, index) => (
              <li key={index} className='underline'>
                <Link to={link.url}>{link.title}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </footer>
  );
};

export const Footer3Defaults: Footer3Props = {
  logo: {
    url: "#",
    src: Logo,
    alt: "Logo image",
  },
  address: {
    label: "Address:",
    value: "301-A, Majestique Biznow, NIBM Road, Pune - 48",
  },
  contact: {
    label: "Contact:",
    phone: "+91 92094 64600",
    email: "info@lowcosys.com",
  },
  columnLinks: [
    {
      links: [
        { title: "Home", url: "/" },
        { title: "About", url: "/about" },
        { title: "Industries", url: "/industries" },
        { title: "Portfolio", url: "/portfolio" },
        { title: "Contact", url: "/contact" },
      ],
    },
    {
      links: [
        { title: "Our Services", url: "/services" },
        { title: "Staff Augmentation", url: "/StaffAugmentation" },
        { title: "Career at LowCoSys", url: "/careers" },
      ],
    },
  ],
  socialMediaLinks: [
    { url: "#", icon: <BiLogoFacebookCircle className='size-6' /> },
    { url: "#", icon: <BiLogoInstagram className='size-6' /> },
    { url: "#", icon: <FaXTwitter className='size-6 p-0.5' /> },
    { url: "#", icon: <BiLogoLinkedinSquare className='size-6' /> },
    { url: "#", icon: <BiLogoYoutube className='size-6' /> },
  ],
  footerText: "© lowcosys.com . All rights reserved.",
  footerLinks: [
    { title: "Privacy Policy", url: "/Privacy-Policy" },
    { title: "Terms of Service", url: "/Terms-Of-Service" },
    { title: "Cookies Policy", url: "/Cookies-Policy" },
  ],
};
