/** @format */

import React from "react";
import { TextField, InputAdornment } from "@mui/material";
import { useField } from "formik";

interface CustomFieldProps {
  name: string;
  label: string;
  type: "text" | "number" | "email" | "date" | "password";
  required?: boolean;
  placeholder?: string;
  disabled?: boolean;
  icon?: React.ReactNode; // Icon for the placeholder
  sx?: object; // MUI sx prop for styling
  multiline?: boolean; // Whether the field should be multiline
  rows?: number; // Number of rows for multiline field
}

const CustomField: React.FC<CustomFieldProps> = ({
  name,
  label,
  type,
  required = false,
  placeholder = "",
  disabled = false,
  icon,
  sx = {},
  multiline = false,
  rows = 1,
}) => {
  const [field, meta] = useField(name);

  return (
    <div style={{ marginBottom: "16px" }}>
      {/* Label rendered above the field */}
      <label
        htmlFor={name}
        style={{
          display: "block",
          fontWeight: 500,
          marginBottom: "6px",
          fontSize: "1rem",
        }}
      >
        {label}
        {required && <span style={{ color: "red", marginLeft: "4px" }}>*</span>}
      </label>

      <TextField
        {...field}
        id={name}
        type={type}
        placeholder={placeholder}
        disabled={disabled}
        fullWidth
        multiline={multiline}
        rows={multiline ? rows : undefined} // Apply rows if multiline is true
        InputProps={{
          startAdornment: icon && (
            <InputAdornment
              position='start'
              sx={{
                alignItems: multiline ? "flex-start" : "center", // Align at the top for multiline
                marginTop: multiline ? "8px" : "0", // Add some spacing for multiline
              }}
            >
              {icon}
            </InputAdornment>
          ),
        }}
        error={meta.touched && Boolean(meta.error)}
        helperText={meta.touched && meta.error ? meta.error : ""}
        sx={{
          "& .MuiInputBase-root": {
            height: multiline ? "auto" : "48px", // Adjust height for multiline
            border: "1px solid",
            borderColor: meta.touched && meta.error ? "red" : "#9CA3AF",
            backgroundColor: "#00000008",
            transition: "border-color 0.3s ease",
          },
          "& .MuiInputBase-root.Mui-focused": {
            borderColor: meta.touched && meta.error ? "red" : "#9CA3AF", // Black border on focus
          },
          "& .MuiOutlinedInput-input": {
            padding: "10px 14px",
          },
          "& .MuiFormHelperText-root": {
            fontSize: "0.8rem",
            color: "red",
          },
          ...sx,
        }}
      />
    </div>
  );
};

export default CustomField;
