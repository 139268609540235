/** @format */

import { Button } from "@relume_io/relume-ui";
import React from "react";
import { colors } from "../constants/theme";
import { Link } from "react-router-dom";

type ButtonProps = {
  children?: React.ReactNode;
  title?: string;
  color?: string;
  variant?: "primary" | "secondary" | "danger";
  path?: string;
  type?: "button" | "submit" | "reset"; // Add this type prop
};

const PrimaryBtn: React.FC<ButtonProps> = ({
  children,
  variant,
  path = "/",
  type = "button", // Default type is 'button'
}) => {
  const buttonClass = `${
    variant === "primary" ? colors.primaryBtn : colors.themeGradient
  }`;

  return (
    <Link to={path}>
      <Button
        className={`w-full rounded-xl text-white border-0 px-4 py-3`}
        style={{ background: buttonClass }}
        type={type} // Forward the type prop to the button
      >
        {children}
      </Button>
    </Link>
  );
};

export default PrimaryBtn;
