/** @format */

import { Navbar } from "../components/navbar";
import { ContactComponent } from "../components/contactComponent";
import { Footer } from "../components/Footer";
import JobApplicationComponent from "../components/JobApplicationComponent";

const JobApplication = () => {
  return (
    <div>
      <Navbar />
      <JobApplicationComponent />
      <ContactComponent />
      <Footer />
    </div>
  );
};

export default JobApplication;
