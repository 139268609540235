/** @format */

import React, { createContext, useContext, useState, ReactNode } from "react";
import setAuthToken from "../../utils/setAuthToken";

interface UserInfo {
  [key: string]: any;
}

interface LoginData {
  token: string;
  user_info: UserInfo;
}

interface AuthContextType {
  isLogin: boolean;
  user: UserInfo | null;
  token: string | null;
  Login: (data: LoginData) => boolean;
  Logout: () => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [isLogin, setIsLogin] = useState<boolean>(() =>
    Boolean(localStorage.getItem("token"))
  );

  const [user, setUser] = useState<UserInfo | null>(() => {
    const storedUser = localStorage.getItem("user_info");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  const [token, setToken] = useState<string | null>(() =>
    localStorage.getItem("token")
  );

  const Login = (data: LoginData): boolean => {
    try {
      if (!data.token || !data.user_info) {
        console.error("Invalid login data");
        return false;
      }
      setAuthToken(data.token);
      localStorage.setItem("token", data.token);
      localStorage.setItem("user_info", JSON.stringify(data.user_info));
      setToken(data.token);
      setUser(data.user_info);
      setIsLogin(true);
      return true;
    } catch (error) {
      console.error("Login failed", error);
      return false;
    }
  };

  const Logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user_info");
    setToken(null);
    setUser(null);
    setIsLogin(false);
  };

  const contextValue: AuthContextType = {
    isLogin,
    user,
    token,
    Login,
    Logout,
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
};

export default AuthContext;
