import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const url = `/Log-In?redirect=${location.pathname}${location.search}`;

  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate(url);
    }
  }, [localStorage.getItem("token")]);

  return <>{children}</>;
};

export default PrivateRoute;
